<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : 'txt-left'"
      >
        {{ $t("view.amount") }}
      </h4>
      <div class="col-12 input-group pd-0" style="padding: 0">
        <input
          id="input"
          class="form-control text-lowercase"
          type="text"
          v-model="row.amount"
          v-on:input="onChange"
        />
        <div class="input-group-append">
          <span class="input-group-text">{{ row.currency }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAmount",
  props: ["bus", "amount", "currency"],
  data() {
    return {
      row: {
        amount: this.$props.amount,
        currency: this.$props.currency,
      },
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      if (!this.row.currency)
        this.row.currency = this.$t(
          "app." + localStorage.getItem("currency_key").toLowerCase()
        );
    }
    if (this.bus != undefined) {
      this.bus.on("amount_changed", (input) => {
        this.row.amount = input.replaceAll(",", "");
      });

      this.bus.on("currency_changed", (currency) => {
        this.row.currency = this.$t("app." + currency.toLowerCase());
      });
    }
  },
  methods: {
    onChange() {
      const data = {
        amount: this.row.amount,
      };
      this.$emit("inputChange", data);
    },
  },
};
</script>
