<template>
  <div class="">
    <h4 class="card-title" :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''">
      {{ $t("view.user_info") }}
    </h4>

    <div class="tab-danger pt-3 row">
      <div class="col-6" v-if="!user.id">
        <CardMobile
          :label="$t('view.mobile')"
          :bus="bus"
          :mobile="user.mobile"
          :country_code="user.country_code"
          @mobileChange="handleMobileChanged"
        />
        <div
          class="item user"
          v-for="user in users"
          :key="user.id"
          @click="handleUserChanged(user)"
        >
          <p>
            <span class="phone">{{ user.country_code + user.mobile }}</span>
            {{ " (" + user.name + ")" }}
          </p>
        </div>
        <div class="text-center" v-if="usersLoading">
          <loading />
        </div>
      </div>

      <div class="col-6" v-if="user.id">
        <label
          for="input1"
          class="col-form-label"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
        >
          {{ $t("view.mobile") }}
        </label>
        <input
          id="input1"
          type="text"
          name="mobile"
          class="form-control phone"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          disabled="disabled"
          :value="user.country_code + user.mobile"
        />
      </div>

      <div class="col-6">
        <label
          for="input1"
          class="col-form-label"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
        >
          {{ $t("view.name") }}
        </label>
        <input
          id="input1"
          type="text"
          name="name"
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          :disabled="user.id ? 'disabled' : false"
          v-model="user.name"
          @change="userChanged"
        />
      </div>

      <div class="col-6">
        <label
          for="input1"
          class="col-form-label"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
        >
          {{ $t("view.email") }}
        </label>
        <input
          id="input1"
          type="text"
          name="email"
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          :disabled="user.id ? 'disabled' : false"
          v-model="user.email"
          @change="userChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardMobile from "@/components/CardMobile";
import iziToast from "izitoast";
import Loading from "@/components/Loading.vue";
import mitt from "mitt";
const bus = mitt();

export default {
  name: "FormClient",
  props: ["client"],
  components: { CardMobile, Loading },
  data() {
    return {
      users: [],
      bus,
      usersLoading: false,
      auth: {
        id_token: "",
      },
      user: {
        name: "",
        email: "",
        mobile: "",
        country_code: "",
        last_invoice_title: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }

    if (this.$props.client) {
      this.user = this.$props.client;
    }
  },
  methods: {
    userChanged() {
      this.$emit("userChanged", this.user);
    },
    handleMobileChanged(event) {
      this.user.country_code = event.code;
      this.user.mobile = event.mobile;
      this.fetchSuggestedList();
    },
    fetchSuggestedList() {
      this.users = [];
      this.usersLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const options = {
        url: "/users",
        method: "GET",
        params: {
          search: this.user.country_code + this.user.mobile,
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.usersLoading = false;
          this.users = res.data.data.rows;
        })
        .catch((err) => {
          this.usersLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
    handleUserChanged(event) {
      this.user.email = event.email;
      this.user.name = event.name;
      this.user.last_invoice_title = event.last_invoice_title;
      this.user.country_code = event.country_code;
      this.user.mobile = event.mobile;
      this.bus.emit("mobile_changed", event.mobile);
      this.userChanged();
      this.users = [];
    },
  },
};
</script>

<style>
.scrollable-menu {
  height: auto;
  max-height: 18rem;
  overflow-x: hidden;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c;
  background-color: #eceeef;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c !important;
  background-color: #eceeef;
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #ced4da;
  border-left: none;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
.el-input--suffix .el-input__inner {
  text-align: left !important;
  direction: ltr;
}
.item {
  width: 100%;
  margin: 0 auto 10px auto;
  padding: 5px;
  color: white;
  border-radius: 5px;
}

.user {
  background-color: #4e046b;
  cursor: pointer;
  font-size: 14px;
}

.el-select-dropdown__item {
  text-align: left;
}
</style>
