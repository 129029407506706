
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardInvoiceSocial",
  props: ["sendInvoice"],
  data() {
    return {
      row: {
        sendInvoice: this.$props.sendInvoice ?? false,
      },
    };
  },
  methods: {
    onSendInvoiceChange() {
      const data = {
        sendInvoice: this.row.sendInvoice,
      };
      this.$emit("sendInvoiceChange", data);
    },

    onRadioChange(value) {
      this.row.sendInvoice = value;
      this.onSendInvoiceChange();
    },
  },
});
