import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["dir"]
const _hoisted_2 = { class: "col-12 form-check mb-3" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = ["checked"]
const _hoisted_5 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", {
      class: _normalizeClass(["card-title mt-5", _ctx.$i18n.locale == 'ar' ? 'font-size-15' : 'txt-left'])
    }, _toDisplayString(_ctx.$t("app.sendInvoice")), 3),
    _createElementVNode("div", {
      class: "font-size-14 col-12 mt-5",
      dir: _ctx.$i18n.locale == 'ar' ? 'rtl' : 'rtl',
      style: _normalizeStyle(_ctx.$i18n.locale == 'ar' ? '' : 'text-align: left')
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          name: "formRadios",
          id: "formRadios2",
          style: {"margin-right":"12px"},
          value: "2",
          checked: _ctx.row.sendInvoice == 4 ? true : false,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRadioChange(4)))
        }, null, 40, _hoisted_3),
        _createElementVNode("label", {
          class: _normalizeClass(["form-check-label", _ctx.$i18n.locale == 'ar' ? 'font-size-15' : '']),
          for: "formRadios2",
          style: {"margin-right":"40px"}
        }, _toDisplayString(_ctx.$t("app.sendWhatsapp")), 3),
        _createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          name: "formRadios",
          id: "formRadios1",
          value: "1",
          style: {"margin-right":"12px"},
          checked: _ctx.row.sendInvoice == 1 ? true : false,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRadioChange(1)))
        }, null, 40, _hoisted_4),
        _createElementVNode("label", {
          class: _normalizeClass(["form-check-label", _ctx.$i18n.locale == 'ar' ? 'font-size-15' : '']),
          for: "formRadios1",
          style: {"margin-right":"40px"}
        }, _toDisplayString(_ctx.$t("app.sendEmail")), 3),
        _createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          name: "formRadios",
          id: "formRadios3",
          style: {"margin-right":"12px"},
          value: "3",
          checked: _ctx.row.sendInvoice == 3 ? true : false,
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onRadioChange(3)))
        }, null, 40, _hoisted_5),
        _createElementVNode("label", {
          class: _normalizeClass(["form-check-label", _ctx.$i18n.locale == 'ar' ? 'font-size-15' : '']),
          for: "formRadios3",
          style: {"margin-right":"40px"}
        }, _toDisplayString(_ctx.$t("app.twice")), 3)
      ])
    ], 12, _hoisted_1)
  ], 64))
}