<template>
  <div class="card" style="width: 100%">
    <div class="card-body">
      <FormClient :client="client" v-on:userChanged="userChanged"> </FormClient>
      <CardInvoiceSocial
        @sendInvoiceChange="handleSendInvoiceChange"
        v-if="hasSendInvoice"
        :sendInvoice="sendInvoice"
      />
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import FormClient from "@/components/FormClient";
import CardInvoiceSocial from "@/components/CardInvoiceSocial";

export default {
  name: "CardUser",
  components: {
    CardInvoiceSocial,
    FormClient,
  },
  props: ["client", "hasSendInvoice", "sendInvoice"],
  data() {
    return {
      auth: {
        id_token: "",
      },
      row: {
        user: this.$props.client,
      },
      users: [],
      userLoading: true,
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }

    this.fetchData();
  },
  methods: {
    fetchData() {
      this.userLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/users",
        method: "GET",
        data: {},
        params: {
          status: 1,
          suspend: 0,
          role: "member",
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.userLoading = false;
          this.users = res.data.data.rows;
        })
        .catch((err) => {
          this.userLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
    userChanged(event) {
      this.$emit("clientChange", event);
    },
    handleSendInvoiceChange(event) {
      this.$emit("sendInvoiceChange", event);
    },
  },
};
</script>
